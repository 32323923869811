<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)" />

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" v-if="$auth.check(adminRoles)" @click="navigate('Tenants.Add')"
            ><b-icon icon="plus" /> {{ $t("views.tenants.button.addtenant") }}</b-button
          >

        </b-col>
      </b-row>

      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(industryCode)="data">
          <b-badge variant="dark" v-html="valueFormatter(data.definition, data.cell.field.key, data.cell.value).html" />
        </template>

        <template #cell(requiredactions)="data">
          <div class="badge-group">
            <b-badge v-for="action in data.cell.item.requiredactions" :key="action" variant="warning">{{ action }}</b-badge>
          </div>
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /users/detail) -->
    <section v-else>
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ data.definition.$properties.name.translations[$i18n.locale].name }}</dt>
            <dd>
              <h1>{{ data.entity.name }}</h1>
            </dd>

            <div class="w-100"></div>
            <dt>{{ $t(`views.${eCurrent.key}.internalid`) }}</dt>
            <dd>{{ data.entity.id }}</dd>
          </dl>
        </template>
      </entity-detail-header>

      <router-view />
    </section>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN, AUTH_ADMIN_ROLES } from "@/constants";

import { TableHelper } from "@/assets/js/helper/table";
import { valueFormatter } from "@/assets/js/helper/entity";

import MixinEntityBase from "@/components/mixins/EntityBase";
import EntityEntryHeader from "@/components/entity/EntryHeader";
import EntityEntryTable from "@/components/entity/EntryTable";
import EntityDetailHeader from "@/components/entity/DetailHeader";

const ENTITY_KEY = "tenants";

export default {
  name: "Tenants",
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader,
  },
  data() {
    return {
      adminRoles: AUTH_ADMIN_ROLES,
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              requiredactions: "cell-align-right",
            },
            thClass: {
              requiredactions: "cell-min cell-nowrap",
            },
          },
        },
        events: {
          rowClicked: this.tableRowClicked,
        },
      },
    };
  },
  computed: {
    isCurrentRoute() {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find((c) => c.name === this.$options.name) !== undefined;
    },
  },
  methods: {
    valueFormatter,
    tableEntitiesMapper(entities, definition) {
      return entities.map((e) => {
        return Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id,
            },
          },
        });
      });
    },
    tableRowClicked(entity) {
      this.$router.push(entity.$routerLink);
    },
  },
  created() {
    TableHelper.rowClassHook((entity) => (entity.$routerLink ? "has-routerlink" : null));
  },
};
</script>

<style lang="scss"></style>
